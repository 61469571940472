import { Box } from "@mui/material";
import { MultiSelect } from "components/common/FormComponents";
import Select from "components/common/FormComponents/Select";
import PopUp from "components/common/PopUp";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ModuleItem } from "types/api";
import { ModuleName, ModuleSettingType } from "types/enum";
import errorMessage from "constants/errorMessage";
import Toggle from "components/common/Toggle";
import { useAppSelector } from "redux/store";
import useActions, { useCurrentSetting } from "./actions";
import { useQuery, useQueryClient } from "react-query";
import { getAllMembersData, getAllResidents } from "utils/api/user";

const settingTypes = [
    { label: "All Resident", value: ModuleSettingType.CareHome },
    { label: "Specified Residents", value: ModuleSettingType.Resident },
    { label: "Specified Family Members", value: ModuleSettingType.Member },
];

const modules = [
    { label: "Care Hub", value: ModuleName.CareHomeHub },
    { label: "Medication", value: ModuleName.Medication },
    { label: "Reflection", value: ModuleName.Reflection },
    { label: "Core Health", value: ModuleName.CoreHealth },
    { label: "Nutrition", value: ModuleName.Nutrition },
    { label: "Billing", value: ModuleName.Billing },
]

interface Props {
    visible: boolean;
    onClose: () => void;
    moduleItem?: ModuleItem;
    index?: number;
}


const NewSettingDialog = ({ visible, onClose, moduleItem, index }: Props) => {
    const [search, setSearch] = useState("");
    const queryClient = useQueryClient();
    const { pccId } = useAppSelector((state) => state.pccInfo);
    const {
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        getValues,
        clearErrors,
        reset,
    } = useForm<ModuleItem>({
        mode: "onChange",
        defaultValues: {
            settingType: ModuleSettingType.CareHome,
            modules: [],
            careHomeId: pccId,
        },
    });



    const onDone = () => {
        onClose();
        reset({
            settingType: ModuleSettingType.CareHome,
            modules: [],
            careHomeId: pccId,
        });
        queryClient.invalidateQueries("getSettings");
    }

    const { data: currentSettings } = useCurrentSetting();

    const { createModuleSetting, loading } = useActions(onDone);

    const { data: residentData
    } = useQuery({
        queryKey: ["getResidentForSettings", pccId, search],
        queryFn: () => getAllResidents({ pccId, page: 1, search, perPage: 20 }),
    });
    const [selectedModules, settingType, selectedResident] = watch(["modules", "settingType", "selectedResident"]);

    const { data: memberData } = useQuery({
        queryKey: ["getFamilyMembersForSettings", selectedResident?.value],
        queryFn: () => {
            return selectedResident ? getAllMembersData(selectedResident.value) : Promise.resolve({ data: [] } as any)
        },
    });

    const residentsOptions = useMemo(() => {
        const residents = residentData?.data?.data || [];
        return residents.map((resident) => ({
            label: `${resident.firstName} ${resident.lastName}`,
            value: resident.id,
        }));
    }, [residentData]);


    useEffect(() => {
        if (selectedResident) {
            setValue('members', []);
        }
    }, [selectedResident]);

    const membersOptions = useMemo(() => {
        const residents = memberData?.data?.data || [];
        return residents.map((member) => ({
            label: `${member.firstName} ${member.lastName} (${member.isPrimary ? "Primary Contact" : 'Member'})`,
            value: member.id,
        }));
    }, [memberData]);

    useEffect(() => {
        if (moduleItem) {
            reset({ ...moduleItem });
        }
        if (moduleItem && residentsOptions.length && moduleItem.settingType === ModuleSettingType.Resident) {
            reset({ ...moduleItem, residents: residentsOptions.filter(option => moduleItem.residents?.includes(option.value)) });
        }
        if (moduleItem && membersOptions.length && moduleItem.settingType === ModuleSettingType.Member) {
            reset({ ...moduleItem, members: membersOptions.filter(option => moduleItem.members?.includes(option.value)) });
        }
    }, [moduleItem, residentsOptions, membersOptions]);

    console.log(getValues());

    const handleClose = () => {
        onClose();
        reset({
            settingType: ModuleSettingType.CareHome,
            modules: [],
            careHomeId: pccId,
        });
    };

    const onSearch = (value: string) => {
        setSearch(value);
    }

    const onSave = () => {
        const members = getValues("members")?.map((item: any) => item.value);
        const settingItem = getValues();
        if (members) {
            settingItem.members = members;
        } else {
            const residents = getValues("residents")?.map((item: any) => item.value);
            if (residents) {
                settingItem.residents = residents;
            }
        }
        if (moduleItem) {
            createModuleSetting({
                settingData: {
                    modules: JSON.stringify(currentSettings.map((item, idx) => {
                        if (idx === index) {
                            return settingItem;
                        }
                        return item;
                    }))
                }
            });
        } else {
            createModuleSetting({
                settingData: {
                    modules: JSON.stringify([
                        ...currentSettings,
                        settingItem
                    ])
                }
            });
        }
    }

    return (
        <div>
            <PopUp
                open={visible}
                handleClose={handleClose}
                heading="Add New Setting"
                actionRight={{ label: loading ? "Saving..." : "Save", disabled: !isValid || loading }}
                actionLeft={{ label: "Cancel", disabled: loading }}
                handleRightBtnClick={onSave}>
                <Box>
                    <Select
                        control={control}
                        label="Setting Type"
                        name={`settingType`}
                        options={settingTypes}
                        errors={errors}
                        rules={{
                            required: errorMessage.required,
                        }}
                    />
                    {settingType === ModuleSettingType.Resident && <MultiSelect
                        control={control}
                        onSearch={onSearch}
                        clearErrors={clearErrors}
                        name="residents"
                        label="Select Residents"
                        typing={false}
                        menuOptions={residentsOptions}
                        rules={{ required: errorMessage.required }}
                        vertical
                    />}
                    <Box mt={2} />
                    {settingType === ModuleSettingType.Member && <MultiSelect
                        control={control}
                        onSearch={onSearch}
                        clearErrors={clearErrors}
                        name="selectedResident"
                        label="Select Resident"
                        typing={false}
                        mutiple={false}
                        menuOptions={residentsOptions}
                        rules={{ required: errorMessage.required }}
                        vertical
                    />}
                    {settingType === ModuleSettingType.Member && <MultiSelect
                        control={control}
                        onSearch={onSearch}
                        clearErrors={clearErrors}
                        name="members"
                        label="Select Members"
                        typing={false}
                        menuOptions={membersOptions}
                        rules={{ required: errorMessage.required }}
                        vertical
                    />}
                    <Box fontWeight="bold" fontSize={14} mb={2} mt={2}>Select modules</Box>
                    {modules.map((module) => (
                        <Box display="flex" key={module.value} mb={1} alignItems="center" justifyContent="space-between">
                            <Box> {module.label}</Box>
                            <Toggle
                                value={selectedModules.includes(module.value)}
                                onChange={(checked) => setValue("modules", checked ? [...selectedModules, module.value] : selectedModules.filter(item => item !== module.value))} />
                        </Box>))}
                </Box>
            </PopUp>
        </div>
    );
}

export default NewSettingDialog;
