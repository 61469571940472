import { Box } from "@mui/system";
import styles from "./styles";
import Button from "components/common/Button";
import { ChangeEvent, useRef, useState } from "react";

interface Props {
  onFileChange: (file: File) => any;
  url?: string;
}


const LogoUploader = ({ onFileChange, url }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>(null);
  const imageUrl = `${process.env.REACT_APP_USER_BASE_URL}/files/${url}`;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(files);
    if (files[0]) {
      setFile(files[0]);
      onFileChange(files[0]);
    }
  }

  return (
    <Box>
      <Box sx={styles.uploader}>
        <Box component="input" accept="image/*" type="file" id="logo-input" ref={ref} sx={styles.hidden} onChange={onChange} />
        {file ?
          <Box component="img" src={URL.createObjectURL(file)} sx={styles.image} /> :
          <Box sx={styles.image} component="img" src={imageUrl} />}
      </Box>
      <Button size="small" sx={styles.button} label="Upload" onClick={() => ref.current.click()} />
      <Box visibility={file ? "visible" : "hidden"} sx={styles.fileName}>{file?.name}</Box>
    </Box >
  );
};

export default LogoUploader;
