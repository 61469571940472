import { Box } from "@mui/material";
import Button from "components/common/Button";
import PopUp from "components/common/PopUp";
import { useState } from "react";
import useActions from "./actions";

interface Props {
    id: string;
    email: string;
    fullName: string;
    label?: string;
}
const SendEmailAction = ({ id, fullName, email, label }: Props) => {
    const [visible, setVisible] = useState(false);
    const { sendEmail } = useActions();


    const onSendEmail = () => {
        sendEmail({ id });
        setVisible(false);
    }


    return <Box>
        {!!visible && (
            <PopUp
                open={!!visible}
                handleClose={() => setVisible(false)}
                heading={`Send email to ${fullName}`}
                actionLeft={{}}
                actionRight={{ label: "Yes" }}
                handleRightBtnClick={onSendEmail}
            >
                {`Are you sure you want to send email to ${email}?`}
            </PopUp>
        )}
        <Button label={label || "Send email"} size="small" onClick={() => setVisible(true)} />
    </Box>;
}

export default SendEmailAction;