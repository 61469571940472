import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import FileLink from "./FileLink";
import Button from "components/common/Button";
import { DeliveryType } from "types/enum";
import SendEmailAction from "./SendEmailAction";

const colorMapping = {
    pending: "#2174e0",
    sent: "green",
    failed: "red"
};

export const columns: GridColDef[] = [
    {
        field: "created_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { created_at } }: GridCellParams) => new Date(created_at).toDateString()

    },
    {
        field: "residentId",
        headerName: "Resident",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { resident } }: GridCellParams) => resident.firstName + " " + resident.lastName
    },
    {
        field: "primaryContactId",
        headerName: "Finance POA",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { primaryContact } }: GridCellParams) => primaryContact.firstName + " " + primaryContact.lastName
    },
    {
        field: "email",
        headerName: "Email",
        sortable: false,
        flex: 2,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "deliveryType",
        headerName: "Delivery",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { deliveryType } }: GridCellParams) => <Box>{deliveryType === "email" ? "Email" : "Postal"}</Box>
    },
    {
        field: "status",
        headerName: "Status",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { status } }: GridCellParams) => <Box color={colorMapping[status]}>{status.charAt(0).toUpperCase() + status.substring(1)}</Box>
    },
    {
        field: "fileId",
        headerName: "File",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { fileId } }: GridCellParams) => <FileLink fileKey={fileId} />
    },
    {
        field: "id",
        headerName: "Actions",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { status, deliveryType, id, primaryContact, email } }: GridCellParams) => status === "pending" && deliveryType === DeliveryType.Email ?
            <SendEmailAction id={id} fullName={primaryContact.firstName + " " + primaryContact.lastName} email={email} /> :
            status === "failed" && deliveryType === DeliveryType.Email && <SendEmailAction id={id} fullName={primaryContact.firstName + " " + primaryContact.lastName} email={email} label="Retry" />
    },

];
