import { Box } from "@mui/system";
import Button from "components/common/Button";
import { useQuery } from "react-query";
import { getSummaryReport } from "utils/api/invoice";

interface Props {
  bulkInvoiceId: string;

}


const SummaryReport = ({ bulkInvoiceId }: Props) => {
  const { data, isLoading } = useQuery(
    ["getInvoiceSummaryReport", bulkInvoiceId],
    () => getSummaryReport(bulkInvoiceId),
    {},
  );


  const openLink = () => {
    window.open(`${process.env.REACT_APP_USER_BASE_URL}/files/${data.data}`, '_blank').focus();
  }

  return (
    <Box>
      <Box>Download your Summary Report below</Box>
      <Box>
        <Button disabled={isLoading} label={isLoading ? "Generating report..." : "Download"} onClick={openLink} />
      </Box>
    </Box >
  );
};

export default SummaryReport;
