import { Styles } from "types/common";

const styles: Styles = {
  header: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  tabs: {
    "&.MuiTabs-root": {
      minHeight: "28px",
      borderBottom: "1px solid gray",
      ".MuiTab-root": {
        padding: "8px 16px",
        minHeight: "28px",
        textTransform: "capitalize",
        "&.Mui-selected": {
          color: "primary.dark",
          fontWeight: "bold"
        }
      },
      ".MuiTabs-indicator": {
        backgroundColor: "primary.dark"
      }
    }
  }


};

export default styles;
