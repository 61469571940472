import { Box } from "@mui/system";
import styles from "./styles";
import DataGrid from "components/common/DataGrid";
import { useQuery } from "react-query";
import { getInvoices } from "utils/api/invoice";
import { DeliveryType } from "types/enum";
import { columns } from "./columns";
import Button from "components/common/Button";
import useActions from "./actions";

interface Props {
  bulkInvoiceId?: string;
}

const EmailInvoices = ({ bulkInvoiceId }: Props) => {
  const { data, isLoading } = useQuery(
    ["getInvoices", bulkInvoiceId],
    () => getInvoices(DeliveryType.Email, bulkInvoiceId),
    {
      onSuccess: (res) => {

      },
    },
  );

  const { sendEmails, loading } = useActions();

  return (
    <Box sx={styles.wrapper}>
      <Box>showing {data?.data.length || 0} invoices for Email Delivery</Box>
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        rowCount={data?.data.length || 0}
        loading={isLoading}
        checkboxSelection
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No email yet."
      />
      <Button label={loading ? "Sending..." : "Send email for all"} size="small" disabled={loading} onClick={() => sendEmails({ bulkInvoiceId })} />
    </Box>
  );
};

export default EmailInvoices;
