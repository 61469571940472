import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "5.5px",
      border: "none",
      borderRadius: "5px",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  sidebar: {
    width: "200px",
    marginRight: "20px",
    div: {
      padding: "5px 10px",
      fontWeight: "bold",
      cursor: "pointer",
      borderRadius: "5px",
      marginBottom: "5px",

    }
  },
  menu: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  active: {
    backgroundColor: "primary.dark",
    color: "custom.text.white",
    "&:hover": {
      backgroundColor: "primary.light",
    },
  },
};

export default styles;
