import AddResident from "components/feature/AddResident";
import Appointments from "components/feature/Appointments";
import Announcements from "pages/announcements";
import Events from "pages/events";
import ForgotPassword from "pages/forgot-password";
import Invoices from "pages/invoices";
import ManageHome from "pages/manage-home";
import ManageResidents from "pages/manage-residents";
import Reporting from "pages/reporting";
import ResetPassword from "pages/reset-password";
import SignIn from "pages/sign-in";
import TermsAndConditions from "pages/terms-and-conditions";
import UserProfile from "pages/user-profile";

type Route = {
  [key: string]: {
    path: string;
    component: () => JSX.Element;
    isPublic?: boolean;
  };
};

export const route: Route = {
  home: {
    path: "/",
    component: ManageHome,
  },
  signIn: {
    path: "/sign-in",
    component: SignIn,
  },
  userProfile: {
    path: "/user-profile",
    component: UserProfile,
  },
  forgotPassword: {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  resetPassword: {
    path: "/reset-password",
    component: ResetPassword,
  },
  manageResidents: {
    path: "/manage-residents",
    component: ManageResidents,
  },
  reporting: {
    path: "/reporting",
    component: Reporting,
  },
  events: {
    path: "/events",
    component: Events,
  },
  announcements: {
    path: "/announcements",
    component: Announcements,
  },
  terms: {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    isPublic: true,
  },
  appointments: {
    path: "/appointments",
    component: Appointments,
  },
  addResident: {
    path: "/add-resident",
    component: AddResident,
  },
  invoices: {
    path: "/invoices",
    component: Invoices,
  },
};

const routesData = {
  defaultRoutes: [
    route.addResident,
    route.userProfile,
    route.announcements,
    route.events,
    route.reporting,
    route.manageResidents,
    route.appointments,
    route.invoices,
    route.home,
  ],
  authRoutes: [route.signIn, route.forgotPassword, route.resetPassword],
  terms: route.terms,
};

export default routesData;
