import { Box, SxProps } from "@mui/system";
import styles from "./styles";
import CareHomeInfoCard from "components/common/CareHomeInfoCard";
import { useState } from "react";
import InvoicesSetting from "./InvoicesSetting";
import ModuleSetting from "./ModuleSetting";

type View = "invoices" | "modules";

const SettingsPage = () => {
  const [view, setView] = useState<View>("invoices");

  return (
    <Box sx={styles.wrapper}>
      <CareHomeInfoCard showToggle={false} />
      <h3>Settings</h3>
      <Box sx={styles.content}>
        <Box sx={styles.sidebar}>
          <Box sx={{ ...(view === "invoices" ? styles.active : {}), ...styles.menu } as SxProps}
            onClick={() => setView("invoices")}>Invoices</Box>
          <Box sx={view === "modules" && styles.active} onClick={() => setView("modules")}>Module</Box>
        </Box>
        <Box sx={styles.content}>
          {view === "invoices" ? <InvoicesSetting /> : <ModuleSetting />}
        </Box>
      </Box>
    </Box >
  );
};

export default SettingsPage;
