import { Box } from "@mui/system";
import styles from "./styles";
import BulkInvoiceTable from "../BulkInvoiceTable";
import Button from "components/common/Button";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import SingleInvoiceTable from "../SingleInvoiceTable";

interface Props {
    onSplitInvoice: () => any
}

const InvoicesView = ({ onSplitInvoice }: Props) => {
    const [tab, setTab] = useState<string>("bulk");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    return (
        <Box marginTop="10px">
            <Box display="flex" justifyContent="space-between" flexDirection="row">
                <Box sx={styles.header}>Invoices</Box>
                <Button
                    label="Split a Bulk Invoice"
                    icon
                    size="small"
                    onClick={onSplitInvoice}
                />
            </Box>
            <Tabs sx={styles.tabs} value={tab} onChange={handleTabChange}>
                <Tab label="Bulk" value="bulk" />
                <Tab label="Single" value="single" />
            </Tabs>
            <Box marginTop="12px">
                {tab === "bulk" ? <BulkInvoiceTable /> : <SingleInvoiceTable />}
            </Box>
        </Box>
    );
};

export default InvoicesView;
