import { Box } from "@mui/system";
import styles from "./styles";
import DataGrid from "components/common/DataGrid";
import { useQuery } from "react-query";
import { getInvoices } from "utils/api/invoice";
import { DeliveryType } from "types/enum";
import { columns } from "./columns";
import Button from "components/common/Button";

interface Props {
  bulkInvoiceId?: string;
  postalFile: string;
}

const PostalInvoices = ({ bulkInvoiceId, postalFile }: Props) => {
  const { data, isLoading } = useQuery(
    ["getInvoicesPostal", bulkInvoiceId],
    () => getInvoices(DeliveryType.Postal, bulkInvoiceId),
    {
      onSuccess: (res) => {

      },
    },
  );

  const openFile = () => {
    window.open(`${process.env.REACT_APP_USER_BASE_URL}/files/${postalFile}`, '_blank').focus();

  }

  return (
    <Box sx={styles.wrapper}>
      <Box>showing {data?.data.length || 0} invoices for Postal Delivery</Box>
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        rowCount={data?.data.length || 0}
        loading={isLoading}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No invoice found."
      />
      <Button label={`Print all postal invoices`} size="small" onClick={openFile} />
    </Box>
  );
};

export default PostalInvoices;
