import InvoicesPage from "components/feature/Invoices";

const Invoices = () => {
  return (
    <>
      <InvoicesPage />
    </>
  );
};

export default Invoices;
