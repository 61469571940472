import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    width: "100%",
  },

  textarea: {
    padding: "12px",
    width: "100%",
    "& .MuiFormControl-root": {
      "&:before, :after, :focus:not(.Mui-disabled):before, ": {
        border: "none",
      },
    },
  },
  label: {
    fontWeight: "bold",
    fontSize: "15px",
  }
};

export default styles;
