import { Box } from "@mui/material";
import styles from "./styles";

export interface Step {
    label: string;
    value: string;
};

type Props = {
    steps: Step[];
    currentStep: number;
};

const Steppers = ({ steps, currentStep }: Props) => {
    const width = (100 / steps.length) * currentStep;

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.stepWrapper}>
                {steps.map((step, index) => <Box sx={styles.step} key={step.value}>
                    <span>{index}</span> {step.label}
                </Box>)}
            </Box>
            <Box sx={styles.progress} width={width + '%'} />
        </Box>
    );
};

export default Steppers;
