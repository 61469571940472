import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {

  },
  filterBox: {
    width: "28px",
    height: "28px",
    borderRadius: "5px",
    border: "solid 1px #7f7f7f",
    marginRight: "25px",
    cursor: "pointer",
    backgroundColor: "white"
  },
  searchWrapper: {
    marginTop: "-20px",
    marginRight: "10px",
    width: "300px"
  },
  filterModal: {
    padding: "20px",
    width: "500px"
  },
  selectBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px"
  },
  selectLabel: {
    marginRight: "10px"
  }
};

export default styles;
