import SettingsPage from "components/feature/Settings";

const Settings = () => {
  return (
    <>
      <SettingsPage />
    </>
  );
};

export default Settings;
