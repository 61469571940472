import { Box } from "@mui/system";
import styles from "./styles";
import DataGrid from "components/common/DataGrid";
import { useQuery } from "react-query";
import { getInvoices } from "utils/api/invoice";
import { DeliveryType } from "types/enum";
import { columns } from "./columns";
import { useEffect, useState } from "react";
import SearchBar from "components/common/SearchField";
import FilterPanel, { Filter } from "./FilterPanel";

interface Props {
  bulkInvoiceId?: string;
}

const SingleInvoiceTable = ({ bulkInvoiceId }: Props) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<Filter>({ deliveryType: "all", status: "all" });
  const { data, isLoading, refetch } = useQuery(
    ["getInvoices", bulkInvoiceId, page, filter, search],
    () => getInvoices(
      filter.deliveryType !== "all" ? filter.deliveryType : DeliveryType.All,
      bulkInvoiceId,
      page,
      filter.status !== "all" ? filter.status : undefined,
      search
    ), {
    onSuccess: (res) => {
      setTotal(res?.metadata?.total);

    },
  },
  );

  const onFilterChange = (newFilter: Filter) => {
    setFilter(newFilter);
  }

  const onSearch = (key: string) => {
    setSearch(key);
  }

  useEffect(() => {
    refetch();
  }, []);


  return (
    <Box sx={styles.wrapper}>
      <Box display="flex" flexDirection="row">
        <Box sx={styles.searchWrapper}>
          <SearchBar getText={onSearch} placeholder="Search by resident name" />
        </Box>
        <FilterPanel onChange={onFilterChange} />
      </Box>
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        rowsPerPageOptions={[10]}
        pageSize={10}
        rowCount={total || 0}
        loading={isLoading}
        onPageChange={(pageInfo) => setPage(pageInfo + 1)}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No email yet."
      />
    </Box>
  );
};

export default SingleInvoiceTable;
