import { Styles } from "types/common";

const styles: Styles = {
  backArrow: {
    marginRight: "10px",
    cursor: "pointer",
  },
  wrapper: {
    display: "inline-block",
  },
  backStyles: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
    color: "custom.background.dropdownCta",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "100%",
      background: "#056EE6",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },
};

export default styles;
