import { Box } from "@mui/system";
import styles from "./styles";
import CareHomeInfoCard from "components/common/CareHomeInfoCard";
import { useState } from "react";
import InvoicesView from "./InvoicesView";
import SplitBulkInvoice from "../SplitBulkInvoice";

const InvoicesPage = () => {
  const [openSplitInvoiceView, setOpenSplitInvoiceView] = useState<boolean>(false);
  const onAdd = () => {

  }

  return (
    <Box sx={styles.wrapper}>
      <CareHomeInfoCard showToggle={false} />

      {openSplitInvoiceView ? <SplitBulkInvoice onBack={() => setOpenSplitInvoiceView(false)} /> : <InvoicesView onSplitInvoice={() => setOpenSplitInvoiceView(true)} />}
    </Box>
  );
};

export default InvoicesPage;
