import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {

  },


};

export default styles;
