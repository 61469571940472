import { Box } from "@mui/system";
import styles from "./styles";
import Button from "components/common/Button";
import { ChangeEvent, useRef, useState } from "react";

interface Props {
  onFileChange: (file: File) => any
}


const Uploader = ({ onFileChange }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(files);
    if (files[0]) {
      setFile(files[0]);
      onFileChange(files[0]);
    }
  }

  return (
    <Box>
      <Box>Export your invoice from Point Click Care and upload here:</Box>
      <Box sx={styles.uploader}>
        <Box marginBottom="10px">Upload your PDF document here</Box>
        <Box component="input" accept="application/pdf" type="file" id="bulk-invoice-input" ref={ref} sx={styles.hidden} onChange={onChange} />
        <Box visibility={file ? "visible" : "hidden"} sx={styles.fileName}>{file?.name}</Box>
        <Button label="Upload" onClick={() => ref.current.click()} />
      </Box>
    </Box >
  );
};

export default Uploader;
