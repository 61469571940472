import { useAppSelector } from "redux/store";
import Info from "../Info";
import { InfoModal } from "components/feature/ManageResidents";


type Props = {
  showToggle?: boolean
};

const CareHomeInfoCard = ({ showToggle }: Props) => {
  const {
    user: {
      globalInfoData,
      mrModalView,
    },
  } = useAppSelector((state) => state);
  const { content, heading } = globalInfoData || {};

  return (
    <Info
      content={content}
      heading={heading}
      mrViewAllModal={mrModalView as InfoModal}
      showToggle={showToggle}
    />

  );
};

export default CareHomeInfoCard;
