import { useState } from "react"
import { useMutation } from "react-query";
import { sendInvoices } from "utils/api/invoice";


interface Data {
    emails: string[],
    postals: string[]
}

interface Params {
    bulkInvoiceId: string;
}

const useActions = (onSuccess: () => any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState<Data | null>(null);

    const { mutate: sendEmails } = useMutation(
        ["sendInvoices"],
        ({ bulkInvoiceId }: Params) => {
            return sendInvoices(bulkInvoiceId);
        },
        {
            onMutate: () => {
                setData(null);
                setError('');
                setLoading(true);
                console.log("mutate");
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                setData(data.data?.data);
                onSuccess();
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
            },
        },
    );


    return { sendEmails, loading, error, data };
}

export default useActions;