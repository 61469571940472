import { Box } from "@mui/material";
import PopUp from "components/common/PopUp";
import { useState } from "react";
import styles from "./styles";
import Select from "components/common/FormComponents/Select";
import { useForm } from "react-hook-form";
import { DeliveryType } from "types/enum";


const allValue = "all";

export interface Filter {
    status?: string;
    deliveryType?: DeliveryType | typeof allValue;
}

interface Props {
    onChange: (filter: Filter) => any
};

const statusOptions = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
    { label: "Sent", value: "sent" },
];

const deliveryOptions = [
    { label: "All", value: "all" },
    { label: "Email", value: "email" },
    { label: "Postal", value: "postal" }
];


const FilterPanel = ({ onChange }: Props) => {
    const [visible, setVisible] = useState(false);

    const {
        control,
        formState: { errors },
        reset,
        getValues,
    } = useForm<Filter>({
        mode: "onChange",
        defaultValues: { status: allValue, deliveryType: allValue },
    });


    const onApply = () => {
        onChange(getValues());
        setVisible(false);
    }

    const onReset = () => {
        reset();
        onChange({ status: "all", deliveryType: "all" });
        setVisible(false);
    }


    return <Box>
        <Box
            sx={styles.filterBox}
            tabIndex={0}
            onClick={() => setVisible(true)}
        >
            <Box
                component="img"
                src="/icons/settingsBox.svg"
                alt="filter"
            />
        </Box>
        {!!visible && (
            <PopUp
                customStyles={{ wrapper: styles.filterModal }}
                open={!!visible}
                handleClose={() => setVisible(false)}
                heading={`Filter invoices`}
                actionLeft={{ label: "Reset", onClick: onReset }}
                actionRight={{ label: "Apply" }}
                handleRightBtnClick={onApply}
            >
                <Box>
                    <Select customStyle={{ containerWrapper: styles.selectBox, input: styles.selectLabel }} control={control}
                        options={statusOptions} name="status" label="Status" errors={errors} />
                </Box>
                <Box>
                    <Select customStyle={{ containerWrapper: styles.selectBox, input: styles.selectLabel }} control={control}
                        options={deliveryOptions} name="deliveryType" label="Delivery Type" errors={errors} />
                </Box>
            </PopUp>
        )}
    </Box>;
}

export default FilterPanel;