import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    width: "100%",
  },

};

export default styles;
