import { Box } from "@mui/system";
import styles from "./styles";
import Button from "components/common/Button";
import { useState } from "react";
import NewSettingDialog from "./NewSettingDialog";
import { ModuleSettingType } from "types/enum";
import PopUp from "components/common/PopUp";
import useDeleteActions from "./NewSettingDialog/deleteActions";
import { useCurrentSetting } from "./NewSettingDialog/actions";
import { Button as MuiButton } from "@mui/material";
import { ModuleItem } from "types/api";

const ModuleTypeLabels = {
  [ModuleSettingType.CareHome]: "All Resident",
  [ModuleSettingType.Resident]: "Specified Residents",
  [ModuleSettingType.Member]: "Specified Family Members",
}

const ModuleNameLabels = {
  CareHomeHub: "Care Hub",
  Medication: "Medication",
  Reflection: "Reflection",
  CoreHealth: "Core Health",
  Nutrition: "Nutrition",
  Billing: "Billing",
}

const ModuleSetting = () => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const { data: modules, refetch, isLoading } = useCurrentSetting();

  const getSubTitle = (module: ModuleItem) => {
    console.log("module", module);
    if (module.settingType === ModuleSettingType.Resident) {
      return `(${module.residents?.length} residents)`;
    }
    if (module.settingType === ModuleSettingType.Member) {
      return `(${module.members?.length} family members)`;
    }
    return "";
  }

  const onDeleteSuccess = () => {
    setConfirmVisible(false);
    setDeleteIndex(null);
    refetch();
  }

  const { deleteModuleSetting, loading: deleting } = useDeleteActions(onDeleteSuccess);

  const onDelete = (index: number) => {
    setDeleteIndex(index);
    setConfirmVisible(true);
  }

  const handleDelete = () => {
    deleteModuleSetting({ index: deleteIndex });
  }

  const handleEdit = (index: number) => {
    setSelectedItem(index);
    setDialogVisible(true);
  }

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setSelectedItem(null);
  }


  return (
    <Box sx={styles.wrapper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Box fontSize={20} fontWeight="bold">Enable/disable Gerry app module</Box>
          <Box fontSize={14} marginBottom={3}>Create module enablement/disablement for all or specified residents.</Box>
        </Box>
        <MuiButton variant="outlined" color="primary" onClick={() => setDialogVisible(true)}>
          Add New Setting
        </MuiButton>
        <NewSettingDialog visible={dialogVisible} onClose={handleCloseDialog} moduleItem={modules[selectedItem]} index={selectedItem} />
      </Box>
      <Box>
        {modules.length === 0 && !isLoading && (
          <Box textAlign="center" marginTop={4} marginBottom={4}>No setting created.</Box>
        )}
        {modules.map((module, index) => (
          <Box key={index} sx={styles.moduleItem}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box fontSize={16} fontWeight="bold">
                {ModuleTypeLabels[module.settingType]} {getSubTitle(module)}
              </Box>
              <Box>
                <Button label="Edit" size="small" style={{ width: 80 }} onClick={() => handleEdit(index)} />
                <Button
                  label="Delete"
                  size="small"
                  onClick={() => onDelete(index)}
                  style={{ width: 80, backgroundColor: "red", marginLeft: 4 }} />
              </Box>
            </Box>
            <Box display="flex" marginTop={1}>
              {module.modules.map((module, index) => (
                <Box key={index} sx={styles.module}>
                  {ModuleNameLabels[module]}
                </Box>
              ))}
            </Box>
            {confirmVisible && <PopUp
              heading="Delete Module Setting"
              open={confirmVisible}
              handleClose={() => setConfirmVisible(false)}
              actionLeft={{
                disabled: deleting,
              }}
              actionRight={{
                label: deleting ? "Processing..." : "Delete",
                disabled: deleting,
              }}
              handleRightBtnClick={handleDelete}>
              <Box>Are you sure you want to delete module setting?</Box>
            </PopUp>}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ModuleSetting;
