import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import FileLink from "../SingleInvoiceTable/FileLink";
import { Box } from "@mui/material";
import { getSummaryReport } from "utils/api/invoice";
import Button from "components/common/Button";
import { useState } from "react";


const PostalInvoices = ({ invoices }: { invoices: any }) => {
    const postals = invoices.filter(invoice => invoice.deliveryType === "postal");

    if (postals.length) return <FileLink fileKey={postals[0]?.fileId} label={`Print (${postals.length})`} />;

    return <Box>No postal found</Box>;
}


const SummaryReport = ({ bulkInvoiceId }: { bulkInvoiceId: string }) => {
    const [isLoading, setLoading] = useState(false);

    const openLink = async () => {
        try {
            setLoading(true);
            const response = await getSummaryReport(bulkInvoiceId);
            const fileKey = response.data;
            window.open(`${process.env.REACT_APP_USER_BASE_URL}/files/${fileKey}`, '_blank').focus();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button disabled={isLoading} label={isLoading ? "Generating report..." : "Download Report"} size="small" onClick={openLink} />
    );
};

export const columns: GridColDef[] = [
    {
        field: "created_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { created_at } }: GridCellParams) => new Date(created_at).toDateString()

    },
    {
        field: "user",
        headerName: "Uploaded By",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { user } }: GridCellParams) => user.firstName + " " + user.lastName
    },
    {
        field: "fileId",
        headerName: "File",
        sortable: false,
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { fileId } }: GridCellParams) => <FileLink fileKey={fileId} label={fileId} />

    },
    {
        field: "invoices",
        headerName: "Total Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => invoices.length
    },
    {
        field: "id",
        headerName: "Postal Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => <PostalInvoices invoices={invoices} />
    },
    {
        field: "id",
        headerName: "Actions",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { id } }: GridCellParams) => <SummaryReport bulkInvoiceId={id} />
    }
];
