import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import FileLink from "../SingleInvoiceTable/FileLink";
import { Box } from "@mui/material";


const PostalInvoices = ({ invoices }: { invoices: any }) => {
    const postals = invoices.filter(invoice => invoice.deliveryType === "postal");

    if (postals.length) return <FileLink fileKey={postals[0]?.fileId} label={`Print (${postals.length})`} />;

    return <Box>No postal found</Box>;
}

export const columns: GridColDef[] = [
    {
        field: "created_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { created_at } }: GridCellParams) => new Date(created_at).toDateString()

    },
    {
        field: "user",
        headerName: "Uploaded By",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { user } }: GridCellParams) => user.firstName + " " + user.lastName
    },
    {
        field: "fileId",
        headerName: "File",
        sortable: false,
        flex: 2,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { fileId } }: GridCellParams) => <FileLink fileKey={fileId} label={fileId} />

    },
    {
        field: "invoices",
        headerName: "Total Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => invoices.length
    },
    {
        field: "id",
        headerName: "Postal Invoices",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { invoices } }: GridCellParams) => <PostalInvoices invoices={invoices} />
    }
];
