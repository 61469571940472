import { Box } from "@mui/system";
import styles from "./styles";
import DataGrid from "components/common/DataGrid";
import { useQuery } from "react-query";
import { getInvoices } from "utils/api/invoice";
import { DeliveryType } from "types/enum";
import { columns } from "./columns";
import Button from "components/common/Button";
import useActions from "./actions";
import { useAppSelector } from "redux/store";
import { Snackbar } from "@mui/material";
import { useState } from "react";
import { set } from "lodash";

interface Props {
  bulkInvoiceId?: string;
}

const EmailInvoices = ({ bulkInvoiceId }: Props) => {
  const [notiVisible, setNotiVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { pccId } = useAppSelector(state => state.pccInfo);
  const { data, isLoading } = useQuery(
    ["getInvoices", bulkInvoiceId],
    () => getInvoices(DeliveryType.Email, pccId, bulkInvoiceId),
    {},
  );

  const onDone = () => {
    setNotiVisible(true);
    setEmailSent(true);
  }

  const { sendEmails, loading } = useActions(onDone);

  return (
    <Box sx={styles.wrapper}>
      <Box>showing {data?.data.length || 0} invoices for Email Delivery</Box>
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        rowCount={data?.data.length || 0}
        loading={isLoading}
        checkboxSelection
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No email yet."
      />
      <Button label={loading ? "Sending..." : (emailSent ? "Emails sent" : "Send email for all")} size="small" disabled={loading || emailSent} onClick={() => sendEmails({ bulkInvoiceId })} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notiVisible}
        onClose={() => setNotiVisible(false)}
        message="Emails are sent successfully"
        key={"email-sent"}
      />
    </Box>
  );
};

export default EmailInvoices;
