import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ComponentProps } from "react";
import { Box } from "@mui/system";

import styles from "./styles";

type BackButtonProps = ComponentProps<typeof Button> & {
  label: string;
  icon?: boolean;
}

const BackButton = ({
  label,
  icon = true,
  onClick,
}: BackButtonProps) => {
  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={styles.backStyles}
        onClick={(event) => onClick(event as any)}
        tabIndex={0}
      >
        {icon && <ArrowBackIosIcon sx={styles.backArrow} />}
        <Box>{label}</Box>
      </Box>
    </Box>
  );
};

export default BackButton;
