import { useState } from "react"
import { useMutation } from "react-query";
import { uploadBulkInvoice } from "utils/api/invoice";

interface BulkInvoice {
    created_at: string;
    fileId: string;
    id: string;
    updated_at: string;
    uploadedBy: string;
}

interface Data {
    bulkInvoice: BulkInvoice,
    emails: string[],
    postals: string[],
    errors: string[],
    postalFile: string
}

interface UploadParams {
    file: File;
    careHomeId: string;
}

const useActions = () => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState<Data | null>(null);


    const onProgress = (value: number) => {
        setProgress(value);
    }

    const { mutate: uploadInvoice } = useMutation(
        ["uploadFile"],
        ({ careHomeId, file }: UploadParams) => {
            return uploadBulkInvoice(careHomeId, file, onProgress);
        },
        {
            onMutate: () => {
                setData(null);
                setError('');
                setProgress(0);
                setLoading(true);
                console.log("mutate");
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                setData(data.data?.data);
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
            },
        },
    );


    return { uploadInvoice, progress, loading, error, data };
}

export default useActions;