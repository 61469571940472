import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    marginTop: "10px",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold"
  },

  detail: {
    fontSize: "12px",
    color: "blue",
    marginLeft: "6px",
    marginTop: "2px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "custom.background.main"
    }
  },

  errorTitle: {
    fontWeight: "bold",
    marginTop: "5px"
  },

  errorMessage: {
    fontSize: "12px",
    color: "error.main"
  }

};

export default styles;
