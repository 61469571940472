import { Box } from "@mui/material";
import Button from "components/common/Button";

interface Props {
    fileKey: string;
    label?: string;
}
const FileLink = ({ fileKey, label }: Props) => {

    const openLink = () => {
        window.open(`${process.env.REACT_APP_USER_BASE_URL}/files/${fileKey}`, '_blank').focus();
    }

    return <Box>
        <Box style={{ textDecoration: "underline", cursor: "pointer" }} color="blue" onClick={openLink} >{label || 'Show File'}</Box>
    </Box>
}

export default FileLink;