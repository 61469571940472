import { Box } from "@mui/system";
import styles from "./styles";
import { CSSProperties, useState } from "react";
import { InputBase, TextareaAutosize } from "@mui/material";
import Button from "components/common/Button";
import { useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { getSettings } from "utils/api/setting";
import useActions from "./actions";
import LogoUploader from "../LogoUploader";

const defaultTemplate = `We hope this message finds you well. We are sending this email on behalf of [Home's Name] to provide you with your monthly invoice statement. Please find the statement attached to this email for your review.

If you have any questions about the details of your invoice or need further clarification, please feel free to reach out to [Contact Name/Title] at [email address] or [phone number]. They will be happy to assist you.

Thank you for your prompt attention to this matter, and we greatly appreciate your continued cooperation.`;


const InvoicesSetting = () => {
  const [bcc, setBcc] = useState<string>("");
  const [subject, setSubject] = useState<string>("Monthly Invoice Statement");
  const [body, setBody] = useState<string>(defaultTemplate);
  const [logo, setLogo] = useState<File>(null);
  const [logoUrl, setLogoUrl] = useState<string>("");

  const { pccId } = useAppSelector((state) => state.pccInfo);
  const { saveSettings, loading: mutating } = useActions();


  const { isLoading } = useQuery(
    ["getSettings", pccId],
    () => getSettings(pccId),
    {
      onSuccess: (res) => {
        console.log(res);
        const {
          invoice_email_bcc: bccEmail,
          invoice_email_subject: emailSubject,
          invoice_email_body: emailBody,
          logo
        } = res;

        bccEmail && setBcc(bccEmail);
        emailSubject && setSubject(emailSubject);
        emailBody && setBody(emailBody);
        logo && setLogoUrl(logo);
      },
    },
  );

  const onSave = () => {
    saveSettings({
      settingData: {
        invoice_email_bcc: bcc,
        invoice_email_subject: subject,
        invoice_email_body: body,
      },
      logo
    });
  };


  return (
    <Box sx={styles.wrapper}>
      <Box fontSize={20} fontWeight="bold" marginBottom={2}>Configurations for Paperless Invoicing</Box>
      <Box sx={styles.label}>BCC Email Address</Box>
      <InputBase value={bcc} disabled={isLoading} onChange={event => setBcc(event.target.value)} />
      <Box fontSize={12} marginBottom={1}>* Enter emails seperated by semicolon(;)</Box>
      <Box sx={styles.label}>Email subject</Box>
      <InputBase value={subject} disabled={isLoading} onChange={event => setSubject(event.target.value)} />
      <Box sx={styles.label} marginTop={1}>Logo</Box>
      <LogoUploader onFileChange={(file) => setLogo(file)} url={logoUrl} />
      <Box sx={styles.label} marginTop={1}>Email template</Box>
      <Box>Dear [Recipient's Name],</Box>
      <TextareaAutosize
        disabled={isLoading}
        style={styles.textarea as CSSProperties}
        value={body}
        onChange={event => setBody(event.target.value)} />
      <Box>Best regards,</Box>
      <Box>[Home's Name] Team</Box>
      <Box display="flex" justifyContent="flex-end" marginTop={2} marginBottom={2}>
        <Button label={mutating ? "Updating..." : "Save"} onClick={onSave} disabled={mutating} />
      </Box>
    </Box>
  );
};

export default InvoicesSetting;
