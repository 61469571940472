import { Styles } from "types/common";

const styles: Styles = {
  uploader: {
    margin: "10px 0px",
    height: "160px",
    borderRadius: "5px",
    border: "1px dashed grey",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  hidden: {
    visibility: "hidden"
  },
  fileName: {
    textDecoration: "underline",
    fontStyle: "italic"
  }

};

export default styles;
