import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {

    borderBottom: "2px solid #cbcbcb",
  },
  stepWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "5px",
  },
  step: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "span": {
      fontWeight: "bold",
      fontSize: "20px",
      marginRight: "4px",
    }
  },
  progress: {
    height: "6px",
    borderRadius: "3px",
    backgroundColor: "primary.dark",
    marginBottom: "-4px"
  }
};

export default styles;
