import { Box } from "@mui/system";
import styles from "./styles";
import Button from "components/common/Button";


const ModuleSetting = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box fontSize={20} fontWeight="bold" marginBottom={1}>Enable/disable Gerry app module</Box>

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        {/* <Button label="Cancel" />
        <Box width={10} /> */}
        <Button label="Save" />
      </Box>
    </Box>
  );
};

export default ModuleSetting;
