import { Box } from "@mui/system";
import styles from "./styles";
import DataGrid from "components/common/DataGrid";
import { useQuery } from "react-query";
import { getBulkInvoices } from "utils/api/invoice";
import { columns } from "./columns";
import { useEffect } from "react";

interface Props {
}

const BulkInvoiceTable = ({ }: Props) => {
  const { data, isLoading, refetch } = useQuery(
    ["getBulkInvoices"],
    () => getBulkInvoices(),
    {
      onSuccess: (res) => {

      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);


  return (
    <Box sx={styles.wrapper}>
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        rowCount={data?.data.length || 0}
        loading={isLoading}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No email yet."
      />
    </Box>
  );
};

export default BulkInvoiceTable;
