import { Styles } from "types/common";

const styles: Styles = {
  uploader: {
    height: "80px",
    width: "80px",
    borderRadius: "5px",
    border: "1px solid grey",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  hidden: {
    visibility: "hidden",
    height: "0px"
  },
  fileName: {
    textDecoration: "underline",
    fontStyle: "italic"
  },
  button: {
    marginTop: "5px",
    width: "80px",
    backgroundColor: "custom.text.white",
    color: "primary.dark",
    height: "24px",
    paddingTop: "6px",
    "&:hover": {
      color: "custom.text.white",
    }
  },
  image: {
    width: "80px",
    height: "80px",
    borderRadius: "5px",
    objectFit: "cover",
    backgroundColor: "custom.background.lightGray"
  }
};

export default styles;
