import { GridCellParams, GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    {
        field: "created_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { created_at } }: GridCellParams) => new Date(created_at).toDateString()

    },
    {
        field: "residentId",
        headerName: "Resident",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { resident } }: GridCellParams) => resident.firstName + " " + resident.lastName
    },
    {
        field: "primaryContactId",
        headerName: "Finance POA",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { primaryContact } }: GridCellParams) => primaryContact.firstName + " " + primaryContact.lastName
    },
    {
        field: "email",
        headerName: "Email",
        sortable: false,
        flex: 2,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "deliveryType",
        headerName: "Delivery",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: () => "Postal"

    },
    {
        field: "status",
        headerName: "Status",
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row: { status } }: GridCellParams) => status.charAt(0).toUpperCase() + status.substring(1)
    },

];
